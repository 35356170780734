'use client';

import {
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { ReactLenis, useLenis } from '@studio-freight/react-lenis';

import MotionPathPlugin from 'gsap/MotionPathPlugin';
import React from 'react';
import ScrollTrigger from 'gsap/ScrollTrigger';
import gsap from 'gsap';

interface ContextProps {
    i18n?: { [x: string]: Record<string, string> | string } | any;
    lang?: string;
    translatedUri?: { [x: string]: Record<string, string> } | null;
    setTranslatedUri?:
        | Dispatch<
              SetStateAction<{
                  [x: string]: Record<string, string> | undefined | null;
              } | null>
          >
        | any;
    pageTitle?: string;
    setPageTitle?:
        | Dispatch<
              SetStateAction<{
                  [x: string]: Record<string, string> | undefined | null;
              } | null>
          >
        | any;
    menus?: {
        [x: string]: Record<string, unknown | Record<string, unknown>[]>[];
    };
    themeOptions?: {
        socials?: Record<string, string>[];
    };
    dict?: Record<string, Record<string, string> | any>;
    lenis?: Record<string, Record<string, string> | any>;
    WPML_domains?: {};
}

const GlobalContext = createContext<ContextProps>({
    i18n: {},
    lang: '',
    translatedUri: {},
    setTranslatedUri: undefined,
    pageTitle: '',
    setPageTitle: undefined,
    menus: {},
    themeOptions: {},
    dict: {},
    lenis: {},
    WPML_domains: {},
});

export const GlobalContextProvider = ({
    children,
    value,
}: {
    children: React.ReactNode;
    value: ContextProps;
}) => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(MotionPathPlugin);

    const [translatedUri, setTranslatedUri] = useState(undefined);
    const [pageTitle, setPageTitle] = useState(undefined);

    const lang = value.lang || '';
    const i18n = value.i18n || {};
    const menus = value.menus || {};
    const themeOptions = value.themeOptions || {};
    const dict = value.dict || {};
    const WPML = value.WPML_domains || {};

    const lenis = useLenis(() => {
        // called every scroll
    });

    return (
        <GlobalContext.Provider
            value={{
                i18n,
                lang,
                translatedUri,
                setTranslatedUri,
                pageTitle,
                setPageTitle,
                menus,
                themeOptions,
                dict,
                lenis,
                WPML_domains: WPML,
            }}>
            <ReactLenis
                root
                options={{
                    duration: 1.8,
                    easing: (t: number) =>
                        Math.min(1, 1.001 - Math.pow(2, -10 * t)),
                }}>
                {children}
            </ReactLenis>
        </GlobalContext.Provider>
    );
};

export const useGlobalContext = () => useContext(GlobalContext);
